import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import about from "../About/About";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import About from "../About/About";
//
// function LoginLayout() {
//   let navigate = useNavigate();
//   const routeChange = () => {
//     let path = `../About/About`;
//     navigate(path);
//   }
// }
function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
        {/*    <h1 style={{ fontSize: "2.6em" }}>*/}
        {/*      LET ME <span className="yellow"> INTRODUCE </span> MYSELF*/}
        {/*    </h1>*/}
        {/*    /!*<p className="home-about-body">*!/*/}
        {/*    /!*  I fell in love with programming and I have at least learnt*!/*/}
        {/*    /!*  something, I think… 🤷‍♂️*!/*/}
        {/*    /!*  <br />*!/*/}
        {/*    /!*  <br />I am fluent in classics like*!/*/}
        {/*    /!*  <i>*!/*/}
        {/*    /!*    <b className="yellow"> C++, Javascript and Python. </b>*!/*/}
        {/*    /!*  </i>*!/*/}
        {/*    /!*  <br />*!/*/}
        {/*    /!*  <br />*!/*/}
        {/*    /!*  My field of Interest's are building new &nbsp;*!/*/}
        {/*    /!*  <i>*!/*/}
        {/*    /!*    <b className="yellow">Web Technologies and Products </b> and*!/*/}
        {/*    /!*    also in areas related to{" "}*!/*/}
        {/*    /!*    <b className="yellow">*!/*/}
        {/*    /!*      Deep Learning and Natural Launguage Processing.*!/*/}
        {/*    /!*    </b>*!/*/}
        {/*    /!*  </i>*!/*/}
        {/*    /!*  <br />*!/*/}
        {/*    /!*  <br />*!/*/}
        {/*    /!*  Whenever possible, I also apply my passion for developing products*!/*/}
        {/*    /!*  with <b className="yellow">Node.js</b> and*!/*/}
        {/*    /!*  <i>*!/*/}
        {/*    /!*    <b className="yellow">*!/*/}
        {/*    /!*      {" "}*!/*/}
        {/*    /!*      Modern Javascript Library and Frameworks*!/*/}
        {/*    /!*    </b>*!/*/}
        {/*    /!*  </i>*!/*/}
        {/*    /!*  &nbsp; like*!/*/}
        {/*    /!*  <i>*!/*/}
        {/*    /!*    <b className="yellow"> React.js and Next.js</b>*!/*/}
        {/*    /!*  </i>*!/*/}
        {/*    /!*</p>*!/*/}

        {/*  </Col>*/}
        {/*  <Col md={4} className="myAvtar">*/}
        {/*    <Tilt>*/}
        {/*      <img src={myImg} className="img-fluid" alt="avatar" />*/}
        {/*    </Tilt>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        {/*<Row>*/}
        {/*  <Col md={12} className="home-about-social">*/}
        {/*    <h1>FIND ME ON</h1>*/}
        {/*    <p>*/}
        {/*      Feel free to <span className="yellow">connect </span>with me (You won't regret it)*/}
        {/*    </p>*/}
        {/*    <ul className="home-about-social-links">*/}
        {/*      <li className="social-icons">*/}
        {/*        <a*/}
        {/*          href="https://github.com/ivssraviraj"*/}
        {/*          target="_blank"*/}
        {/*          rel="noreferrer"*/}
        {/*          className="icon-colour  home-social-icons"*/}
        {/*        >*/}
        {/*          <AiFillGithub />*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li className="social-icons">*/}
        {/*        <a*/}
        {/*          href="https://twitter.com/ravirajivss"*/}
        {/*          target="_blank"*/}
        {/*          rel="noreferrer"*/}
        {/*          className="icon-colour  home-social-icons"*/}
        {/*        >*/}
        {/*          <AiOutlineTwitter />*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li className="social-icons">*/}
        {/*        <a*/}
        {/*          href="https://www.linkedin.com/ravirajivss/"*/}
        {/*          target="_blank"*/}
        {/*          rel="noreferrer"*/}
        {/*          className="icon-colour  home-social-icons"*/}
        {/*        >*/}
        {/*          <FaLinkedinIn />*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*      <li className="social-icons">*/}
        {/*        <a*/}
        {/*          href="https://www.instagram.com/ivss_raviraj"*/}
        {/*          target="_blank"*/}
        {/*          rel="noreferrer"*/}
        {/*          className="icon-colour home-social-icons"*/}
        {/*        >*/}
        {/*          <AiFillInstagram />*/}
        {/*        </a>*/}
        {/*      </li>*/}
        {/*    </ul>*/}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
